import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Row, Spinner, Container, Card, CardBody } from "reactstrap"
import API from "../../api"
import ISGGB from "../../assets/images/flshs.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const EmploiGroupView = props => {
  const [loading, setLoading] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [semestre, setSemestre] = useState([])
  const [group, setGroup] = useState([])
  const [annee, setAnnee] = useState("");
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id_semestre = array[1]
    var array1 = id_semestre.split("/")
    var id = array1[0]
    var semestre_id = array1[1]
    var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
    var annee_id = userAuthEnseignant.user.annee_id

     API.post("annee/get_by_id", { id: annee_id }).then(resD =>{
       setAnnee(resD.data.Annee.annee_scolaire);
     });


    const resE = await API.post("emploi/get_by_group", {
      group_id: id,
      semestre_id: semestre_id,
      annee_id:annee_id
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setSemestre(resE.data.emploi[0].semestre)
      setGroup(resE.data.emploi[0].group)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <div className="container">
                    <div className="row" style={{ paddingTop: "30px" }}>
                      <div
                        className="col"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        République Tunisienne
                        <div
                          className="col"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {"Ministère de l'enseignement supérieur,"}
                        </div>
                        <div
                          className="col"
                          style={{ fontSize: "12px", textAlign: "center" }}
                        >
                          {" "}
                          de la Recherche Scientifique
                        </div>
                        <div
                          className="col"
                          style={{
                            marginLeft: 0,
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            paddingTop: "10px",
                          }}
                        >
                          Université de Sfax
                        </div>
                        <div
                          className="col"
                          style={{
                            marginLeft: 0,
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {"Faculté des Lettres et des Sciences Humaines de Sfax"}
                        </div>
                      </div>
                      <div
                        className="col"
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <img style={{ width: "45%" }} src={ISGGB} />
                      </div>
                      <div
                        className="col"
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <img style={{ width: "28%" }} src={tunisie_rep} />
                      </div>
                      <div
                        className="col"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        الجمهورية التونسية
                        <div
                          className="col"
                          style={{ fontSize: "12px", textAlign: "center" }}
                        >
                          وزارة التعليم العالي والبحث العلمي
                        </div>
                        <div
                          className="col"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            paddingTop: "10px",
                          }}
                        >
                          جامعة صفافس
                        </div>
                        <div
                          className="col"
                          style={{
                            textAlign: "center",
                            marginRight: 0,
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          كلية الآداب و العلوم الانسانية بصفاقس{" "}
                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        width: "100%",
                        marginTop: "0.1%",
                        color: "black",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontSize: "13px",
                        justifyContent: "end",
                      }}
                    >
                      <span style={{ paddingRight: "4px" }}>
                        Année Universitaire:{" "}
                      </span>
                      <span style={{ fontWeight: "bold" }}>{annee}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "13px",
                        justifyContent: "end",
                      }}
                    >
                      <span style={{ paddingRight: "4px" }}>Semestre:</span>
                      <span style={{ fontWeight: "bold" }}>{semestre}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "15px",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ paddingRight: "4px" }}>Groupe:</span>
                      <span style={{ fontWeight: "bold" }}>{group}</span>
                    </div>
                  </div>
                  <div>
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered text-center">
                        <thead className="table table-light">
                          <tr>
                            <th></th>
                            {quarts.map(el => (
                              <th
                                key={el.id_quart}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {el.label_quart}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {jours.map((elem, index) => (
                            <tr key={elem.id_jour}>
                              <td style={{ fontWeight: "bold" }}>
                                {elem.label_jour}
                              </td>
                              {elem.seances.map((element, indice) =>
                                element.type != null ? (
                                  element.quan == 1 ? (
                                    <td
                                      key={indice}
                                      style={{ padding: "0px" }}
                                      className="td-emploi"
                                    >
                                      {element.options.map((elle, ind) => (
                                        <div key={ind}>
                                          <div>
                                            <div
                                              style={{
                                                padding: "2px 2px 2px 2px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {elle.label_matiere}
                                              </p>
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {elle.label_enseignant}
                                              </p>
                                              {elle.type == 3 ? (
                                                <p
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {elle.group_tp}
                                                </p>
                                              ) : null}
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {elle.label_salle}
                                              </p>
                                              {elle.type == 1 ? (
                                                <div className="div-icon">
                                                  <span className="icon-cour">
                                                    C
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              ) : elle.type == 2 ? (
                                                <div className="div-icon">
                                                  <span className="icon-td">
                                                    TD
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              ) : elle.type == 3 ? (
                                                <div className="div-icon">
                                                  <span className="icon-tp">
                                                    TP
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              ) : (
                                                <div className="div-icon">
                                                  <span className="icon-autre">
                                                    A
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </td>
                                  ) : (
                                    <td
                                      style={{ padding: "0px" }}
                                      className="td-emploi"
                                    >
                                      {element.options.map((elle, ind) => (
                                        <div
                                          className=""
                                          key={indice}
                                          style={{
                                            padding: "2px 2px 2px 2px",
                                            cursor: "pointer",
                                            borderBottom:
                                              ind == 0 &&
                                              element.options.length > 1
                                                ? "1px solid #eff2f7"
                                                : null,
                                          }}
                                        >
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_matiere}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_enseignant}
                                          </p>
                                          {elle.type == 3 ? (
                                            <p
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              {elle.group_tp}
                                            </p>
                                          ) : null}
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_salle}
                                          </p>
                                          {elle.rattrapage == 1 ? (
                                            <div className="div-icon">
                                              <span className="icon-rattrapage">
                                                R
                                              </span>
                                            </div>
                                          ) : null}
                                          {elle.type == 1 ? (
                                            <div className="div-icon">
                                              <span className="icon-cour">
                                                C
                                              </span>
                                            </div>
                                          ) : elle.type == 2 ? (
                                            <div className="div-icon">
                                              <span className="icon-td">
                                                TD
                                              </span>
                                            </div>
                                          ) : elle.type == 3 ? (
                                            <div className="div-icon">
                                              <span className="icon-tp">
                                                TP
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="div-icon">
                                              <span className="icon-autre">
                                                A
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </td>
                                  )
                                ) : (
                                  <td className="td-emploi"></td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/SchedulesGroups")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmploiGroupView
EmploiGroupView.propTypes = {
  history: PropTypes.object,
}
