import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalBody, Button, CardTitle, Label, Form } from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import FileDownload from "js-file-download"
import Select from "react-select"
import API from "../../apis"

const DataTableValidationFicheVoeux = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var departement_id = userAuthEnseignant.user.departement_id
  var annee_id = userAuthEnseignant.user.annee_id
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  //
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [id, setId] = useState("")
  // Data Table
  const [orders, setOrders] = useState([])
  const [nbGroupe, setNbGroupe] = useState("")
  const [validationSearch, setValidationSearch] = useState([
    { value: 1, label: "Liste en attendre de validation par l'autre chef de département" },
    { value: 2, label: "Liste de validation des voeaux d'autre département" }
  ])
  const [selectValidationSearch, setSelectValidationSearch] = useState("")

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5
      },
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "30",
        value: 30
      },
      {
        text: "All",
        value: orders.length
      }
    ]
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [

    {
      dataField: "departement",
      text: props.t("Département"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "semestre",
      text: props.t("Semestre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "specialite",
      text: props.t("Spécialité"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "niveau",
      text: props.t("Niveau"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "matiere",
      text: props.t("Matière"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "enseignant",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "",
      text: props.t(""),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: "Action",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.type === 1 && row.valide === 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#FF0000", cursor: "cursor" }}
              className="fas fa-spinner fa-pulse"
              title="En cours de validation"
            ></i>
          </div>
        ) : row.type === 1 && row.valide === 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#2ca67a", cursor: "cursor" }}
              className="fas fa-check"
              title="Validée"
            ></i>
          </div>
        ) : row.type === 1 && row.valide === 2 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#FF0000", cursor: "cursor" }}
              className="fas fa-times"
              title="Refusée"
            ></i>
          </div>
        ): row.type === 0 && row.valide === 0 ?(
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#18ae3d", cursor: "pointer" }}
              className="fas fa-cogs"
              title="Veuillez-vous valider ?"
              onClick={() => toggle(row)}
            ></i>
          </div>
        ): row.type === 0 && row.valide === 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a13939", cursor: "pointer" }}
              className="fas fa-times"
              title="Veuillez-vous valider ?"
              onClick={() => toggle1(row)}
            ></i>
          </div>
        ) : row.type === 0 && row.valide === 2 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a13939", cursor: "pointer" }}
              className="fas fa-times"
              title="Refusée"
            ></i>
          </div>
        ):(null)
    }

  ]

  const ExportExcel = async (semestre_id) => {
    const res = await APIS.post(`enseignant/fiche_voeu_export_by_ens`, {
      annee_id,
      semestre_id,
      enseignant_id
    }, { responseType: "blob" }).then(res => {
      FileDownload(res.data, "Export fiche de veaux.xlsx")
    })
  }
  const { SearchBar } = Search


  const toggle = row => {
    setModal(!modal)
    setId(row.fiche_ligne_id)
    setNbGroupe(row.nb_group)
  }
  const toggle1 = row => {
    setModal1(!modal1)
    setId(row.fiche_ligne_id)
    setNbGroupe(row.nb_group)
  }

  const toggleValider = async () => {
    setModal(!modal)
    const res = await APIS.post("fiche/valider", {
      id: id,
      etat: 1,
      nb_group: nbGroupe,
    }).then(res => {
      if(selectValidationSearch.value == 1){
        APIS.post("fiche/list_chef_libre", {
          departement_id: departement_id,
          annee_id: annee_id,
          enseignant_id
        }).then(res => {
          setOrders(res.data.fiches)
        })
      }else{
        APIS.post("fiche/list_libre", {
          departement_id: departement_id,
          annee_id: annee_id,
          enseignant_id
        }).then(res => {
          setOrders(res.data.fiches)
        })
      }
      setLoading(true)
    })
  }
  const toggleRefuse = async () => {
    setModal(!modal)
    const res = await APIS.post("fiche/valider", {
      id: id,
      etat: 2,
    }).then(res => {
      if(selectValidationSearch.value == 1){
        APIS.post("fiche/list_chef_libre", {
          departement_id: departement_id,
          annee_id: annee_id,
          enseignant_id
        }).then(res => {
          setOrders(res.data.fiches)
        })
      }else{
        APIS.post("fiche/list_libre", {
          departement_id: departement_id,
          annee_id: annee_id,
          enseignant_id
        }).then(res => {
          setOrders(res.data.fiches)
        })
      }
      setLoading(true)
    })
  }
  const toggleDesValider = async () => {
    setModal1(!modal1)
    const res = await APIS.post("fiche/valider", {
      id: id,
      etat: 0,
      nb_group: nbGroupe,
    }).then(res => {
      if(selectValidationSearch.value == 1){
        APIS.post("fiche/list_chef_libre", {
          departement_id: departement_id,
          annee_id: annee_id,
          enseignant_id
        }).then(res => {
          setOrders(res.data.fiches)
        })
      }else{
        APIS.post("fiche/list_libre", {
          departement_id: departement_id,
          annee_id: annee_id,
          enseignant_id
        }).then(res => {
          setOrders(res.data.fiches)
        })
      }
      setLoading(true)
    })
  }

  useEffect(() => {
    setModal(false)
    setLoading(true)
  }, [orders])

  const getList = async (e) => {
    setSelectValidationSearch(e)
    if (e.value == 2) {
      const res = await APIS.post("fiche/list_libre", {
        departement_id: departement_id,
        annee_id: annee_id,
        enseignant_id
      }).then(res => {
        setOrders(res.data.fiches)
      })
      setLoading(true)
    } else {
      const res = await APIS.post("fiche/list_chef_libre", {
        departement_id: departement_id,
        annee_id: annee_id,
        enseignant_id
      }).then(res => {
        setOrders(res.data.fiches)
      })
      setLoading(true)
    }

  }
  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Filtrer
                </Label>
                <Select
                  options={validationSearch}
                  isSearchable={true}
                  onChange={e => getList(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      {/* <> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px"
          }}
          toggle={toggle}
        >
          {props.t("Validation de fiche de voeux")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {" "}
                {props.t(
                  "Êtes-Vous sûr de vouloir valider cette fiche de voeux ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px"
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleValider}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Valider")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleRefuse}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Refuser")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px"
          }}
          toggle={toggle1}
        >
          {props.t("Désvalider de fiche de voeux")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {" "}
                {props.t(
                  "Êtes-Vous sûr de vouloir désvalider cette fiche de voeux ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px"
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDesValider}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableValidationFicheVoeux))
DataTableValidationFicheVoeux.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any
}
