import React, {  useState } from "react"
import PropTypes from "prop-types"
import {  Row,  Col,  Spinner,CardTitle, Form, Label, Table,} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Select from "react-select";
import API from "../../apis";

const DataTableRegistre = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [etudiantList, setEtudiantList] = useState([])
  const getMatiere = async (e) => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      semestre_id: selectSemestre.value,
      enseignant_id: enseignant_id,
      annee_id:annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }
  const getType = async (e) => {
    setSelectSemestre(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await API.post("emploi/get_type_matiere", {
      annee_id:annee_id,
      enseignant_id: enseignant_id,
      semestre_id: e.value,
    }).then(res => {
      setType(res.data.emploi)
    })
  }
  const getGroup = async (e) => {
    setSelectMatiere(e)
    setSelectGroupe("")
    const res = await API.post("registre/get_group_by_enseignant_id", {
      enseignant_id: enseignant_id,
      matiere_id:e.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setGroupe(res.data.Group)
    })
  }
  const getDate = async (e) => {
    setSelectGroupe(e)
    setSelectDate("")
    const res = await API.post("registre/get_date", {
      enseignant_id: enseignant_id,
      matiere_id:selectMatiere.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      group_id:e.value
    }).then(res => {
      setDate(res.data.Date)
    })
  }
  const getList = async (e) => {
    setSelectDate(e)
    API.post("registre/get_etudiant", {
      enseignant_id: enseignant_id,
      matiere_id:selectMatiere.value,
      type_matiere:selectType.value,
      semestre_id:selectSemestre.value,
      annee_id:annee_id,
      group_id:selectGroupe.value,
      date:e.label
    }).then(res => {
      setEtudiantList(res.data.Etudiant.etudiant)
      setLoading(true)
    })
  }
  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Semestre
                </Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    onChange={e => getType(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                    options={type}
                    isSearchable={true}
                    value={selectType}
                    onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                    options={matiere}
                    isSearchable={true}
                    value={selectMatiere}
                    onChange={e => getGroup(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => getDate(e)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Date
                </Label>
                <Select
                    options={date}
                    isSearchable={true}
                    onChange={e => getList(e)}
                    value={selectDate}
                />
              </div>
            </Col>
          </Row>
        </Form>
        {loading ? (
          <Row>
            <Col xs="12">
              <Table className="mt-3" striped bordered hover>
                <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>CIN</th>
                  <th style={{ textAlign: "center" }}>Etudiant</th>
                  <th style={{ textAlign: "center" }}>Présence</th>
                </tr>
                </thead>
                <tbody>
                {etudiantList.map((el, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}> {el.cin} </td>
                      <td style={{ textAlign: "center" }}> {el.name} </td>
                      <td className="square-switch" style={{ textAlign: "center" }} >
                        <input
                            type="checkbox"
                            id={"square-switch" + index}
                            switch="bool"
                            checked={el.presence}
                            disabled={el.presence == 1 ? true : false}
                        />
                        <label
                            htmlFor={"square-switch" + index}
                            data-on-label="P"
                            data-off-label="A"
                        />
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableRegistre))
DataTableRegistre.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
