import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  CardTitle,
  Col,
  Form,
  Label,
  Row,
  Modal,
  ModalBody,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const FicheEnseignant = props => {
  const [selectJour, setSelectJour] = useState("")
  const [selectSemestre, setSelectSemestre] = useState("")
  const [arrayFiche, setArrayFiche] = useState([
    { ordre: "", niveau_id: "", specialite_id: "", matiere_id: "", type: "" },
  ])
  const [arrayFiche1, setArrayFiche1] = useState([
    {
      ordre1: "",
      niveau_id1: "",
      specialite_id1: "",
      matiere_id1: "",
      type1: "",
    },
  ])
  const [arrayFiche2, setArrayFiche2] = useState([
    {
      ordre2: "",
      niveau_id2: "",
      specialite_id2: "",
      matiere_id2: "",
      type2: "",
    },
  ])
  const [loading, setLoading] = useState(false)
  const [session, setSession] = useState([
    {value:1,label:"Matin"},
    {value:2,label:"Après-Midi"},
  ])
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [modalJours, setModalJours] = useState(false)
  const [arrayJour, setArrayJour] = useState([{ jour_id: "" ,session:""}])

  const addJour = (event, index) => {
    let jour_id = event
    setArrayJour(
        arrayJour.map((el, id) =>
            id === index ? Object.assign(el, { jour_id: jour_id }) : el
        )
    )
  }
  const addSession = (event, index) => {
    let session = event
    setArrayJour(
        arrayJour.map((el, id) =>
            id === index ? Object.assign(el, { session: session }) : el
        )
    )
  }
  const [indexLigneJours, setIndexLigneJours] = useState("")
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await APIS.post("fiche/get_by_id", { id }).then(res => {
      setSelectSemestre(res.data.FicheVoeau.semestre_id)
      setSelectJour(res.data.FicheVoeau.jour_id)
      setArrayFiche(res.data.FicheVoeau.ligne)
      setArrayFiche1(res.data.FicheVoeau.ligne1)
      setArrayFiche2(res.data.FicheVoeau.ligne2)
      setArrayJour(res.data.FicheVoeau.jour_id)
      setLoading(true)
    })
  }, [])
  const toggleJours = indexOfLigne => {
    setModalJours(!modalJours)
    setIndexLigne(indexOfLigne)
  }
  const toggleDeleteJours = indexOfLigne => {
    arrayJour.splice(indexLigneJours, 1)
    setModalJours(false)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Semestre")} :
                          </Label>
                          <Select isSearchable={true} value={selectSemestre} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label for="basicpill-firstname-input1">
                        {props.t("Choix des jours")} :
                      </Label>
                      <table className="table table-bordered mt-4">
                        <thead>
                        <tr>
                          <th style={{textAlign: "center"}} scope="col">
                            Jours
                          </th>
                          <th style={{textAlign: "center"}} scope="col">
                            Choix du jours
                          </th>
                        </tr>
                        </thead>
                        {arrayJour.map((el, index) => (
                            <tbody key={index}>
                            <tr>
                              <td style={{textAlign: "center"}} scope="col">
                                <Select
                                    options={jours}
                                    isSearchable={true}
                                    value={el.jour_id}
                                    onChange={e => addJour(e, index)}
                                />
                              </td>
                              <td style={{textAlign: "center"}} scope="col">
                                <Select
                                    value={el.session}
                                    options={session}
                                    isSearchable={true}
                                    onChange={e => addSession(e, index)}
                                />
                              </td>
                            </tr>
                            </tbody>
                        ))}
                      </table>
                    </Row>
                    <div className="mt-4 mb-4">
                      <h5>
                        Veuillez indiquer vos choix concernant les matières que
                        vous désiriez assurer au cours de l’année universitaire
                        2023-2024, et de préciser la forme d’enseignement voulue
                        (Cours, TD, TP, Autre).
                      </h5>
                    </div>
                    <CardTitle
                      style={{ color: "#556ee6" }}
                      className="h4 mb-2 mt-2"
                    >
                      Choix des matiéres pour licence:
                    </CardTitle>
                    <table className="table table-bordered mt-4">
                      <thead>
                        <tr>

                          <th style={{ textAlign: "center" }} scope="col">
                            Niveau
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Spécialité
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Matiere
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Type seance
                          </th>
                        </tr>
                      </thead>
                      {arrayFiche.map((el, index) => (
                        <tbody key={index}>
                          <tr>

                            <td className="col-md-2">
                              <Select
                                isSearchable={true}
                                value={el.niveau_id}
                              />
                            </td>
                            <td className="col-md-3">
                              <Select
                                isSearchable={true}
                                value={el.specialite_id}
                              />
                            </td>
                            <td className="col-md-3">
                              <Select
                                isSearchable={true}
                                value={el.matiere_id}
                              />
                            </td>
                            <td className="col-md-2">
                              <Select isSearchable={true} value={el.type} />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <CardTitle
                      style={{ color: "#556ee6" }}
                      className="h4 mb-2 mt-2"
                    >
                      Choix des matiéres pour mastère:
                    </CardTitle>
                    <table className="table table-bordered mt-4">
                      <thead>
                        <tr>

                          <th style={{ textAlign: "center" }} scope="col">
                            Niveau
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Spécialité
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Matiere
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Type seance
                          </th>
                        </tr>
                      </thead>
                      {arrayFiche1.map((el, index) => (
                        <tbody key={index}>
                          <tr>

                            <td className="col-md-2">
                              <Select
                                isSearchable={true}
                                value={el.niveau_id1}
                              />
                            </td>
                            <td className="col-md-3">
                              <Select
                                isSearchable={true}
                                value={el.specialite_id1}
                              />
                            </td>
                            <td className="col-md-3">
                              <Select
                                isSearchable={true}
                                value={el.matiere_id1}
                              />
                            </td>
                            <td className="col-md-2">
                              <Select isSearchable={true} value={el.type1} />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    {/*<CardTitle*/}
                    {/*  style={{ color: "#556ee6" }}*/}
                    {/*  className="h4 mb-2 mt-2"*/}
                    {/*>*/}
                    {/*  Choix des matiéres pour mastère professionnel:*/}
                    {/*</CardTitle>*/}
                    {/*<table className="table table-bordered mt-4">*/}
                    {/*  <thead>*/}
                    {/*    <tr>*/}

                    {/*      <th style={{ textAlign: "center" }} scope="col">*/}
                    {/*        Niveau*/}
                    {/*      </th>*/}
                    {/*      <th style={{ textAlign: "center" }} scope="col">*/}
                    {/*        Spécialité*/}
                    {/*      </th>*/}
                    {/*      <th style={{ textAlign: "center" }} scope="col">*/}
                    {/*        Matiere*/}
                    {/*      </th>*/}
                    {/*      <th style={{ textAlign: "center" }} scope="col">*/}
                    {/*        Type seance*/}
                    {/*      </th>*/}
                    {/*    </tr>*/}
                    {/*  </thead>*/}
                    {/*  {arrayFiche2.map((el, index) => (*/}
                    {/*    <tbody key={index}>*/}
                    {/*      <tr>*/}

                    {/*        <td className="col-md-2">*/}
                    {/*          <Select*/}
                    {/*            isSearchable={true}*/}
                    {/*            value={el.niveau_id1}*/}
                    {/*          />*/}
                    {/*        </td>*/}
                    {/*        <td className="col-md-3">*/}
                    {/*          <Select*/}
                    {/*            isSearchable={true}*/}
                    {/*            value={el.specialite_id1}*/}
                    {/*          />*/}
                    {/*        </td>*/}
                    {/*        <td className="col-md-3">*/}
                    {/*          <Select*/}
                    {/*            isSearchable={true}*/}
                    {/*            value={el.matiere_id1}*/}
                    {/*          />*/}
                    {/*        </td>*/}
                    {/*        <td className="col-md-2">*/}
                    {/*          <Select isSearchable={true} value={el.type1} />*/}
                    {/*        </td>*/}
                    {/*      </tr>*/}
                    {/*    </tbody>*/}
                    {/*  ))}*/}
                    {/*</table>*/}
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/FicheVoeux")}
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
              <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(FicheEnseignant)
FicheEnseignant.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
