import React, { Component } from "react"
import { Container, Row } from "reactstrap"
import MetaTags from 'react-meta-tags';

import NewsGrid from "./NewsGrid";

export default class News extends Component {

  render() {
    return (
      <React.Fragment>
        <Row>
          <NewsGrid />
        </Row>
      </React.Fragment>
    )
  }
}
