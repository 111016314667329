import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button, Label, Form, Input, Progress
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import apis from "apis"
import Select from "react-select"
import APIS from "../../apis"
import getDay from "date-fns/getDay"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from "react-toastify"
import FileDownload from "js-file-download"

const DataTableAvisAbsence = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  //
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalRat, setModalRat] = useState(false)
  const [modalJustification, setModalJustification] = useState(false)
  const [id, setId] = useState("")
  const [date, setDate] = useState(null)
  const [quart, setQuart] = useState([])
  const [selectQuart, setSelectQuart] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [jourId, setJourId] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" }
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [avisAbsence, setAvisAbsence] = useState([])
  const [file, setFile] = useState(null)
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: avisAbsence.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5
      },
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "30",
        value: 30
      },
      {
        text: "All",
        value: avisAbsence.length
      }
    ]
  }
  const [uploadPercent, setUploadPercent] = useState(0)
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const Columns = () => [
    {
      dataField: "date_debut",
      text: props.t("Date début"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "date_fin",
      text: props.t("Date fin"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "TypeAbsence",
      text: props.t("Type Absence"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      }
    },
    {
      dataField: "Télécharger justification",
      text: props.t("Télécharger Justification"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.etat == 1 && row.justification == null ? (
          <i
            style={{ color: "#955cd5", cursor: "pointer" }}
            onClick={() => download2(row)}
            className="fas fa-file-download"
          ></i>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-file-download"
              title="Avis d'absence accepté"
            ></i>
          </div>
        )
      )
    },
    {
      dataField: "Justification",
      text: props.t("Justification"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <i
          style={{ color: "#955cd5", cursor: "pointer" }}
          onClick={() => toggleJustification(row.id)}

          className="fas fa-file-download"
        ></i>
      )
    },
    {
      dataField: "Modification",
      isDummyField: true,
      text: props.t("Modification"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.etat == 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/EditAvisAbsence?id=" + row.id}>
              <i
                style={{ color: "cornflowerblue", cursor: "pointer" }}
                className="fas fa-edit"
              ></i>
            </Link>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-edit"
              title="Avis d'absence accepté"
            ></i>
          </div>
        )
      )
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: props.t("Suppression"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.etat == 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ED6464", cursor: "pointer" }}
              onClick={() => toggle(row.id)}
              className="fas fa-trash-alt"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-trash-alt"
              title="Avis d'absence accepté"
            ></i>
          </div>
        )
      )
    },
    {
      dataField: "Date de rattrapage",
      isDummyField: true,
      text: props.t("Date de rattrapage"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.etat == 1 && row.justification != null ? (
          row.count == 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                style={{ color: "#0694ff", cursor: "pointer" }}
                onClick={() => toggleRat(row.id)}
                className="fas fa-calendar-day"
              ></i>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                style={{ color: "#a7a3a3", cursor: "cursor" }}
                className="fas fa-calendar-day"
                title="Vous avez déjà une avis d'absence"
              ></i>
            </div>
          )
        ) : (
          row.etat === 1 && row.justification == null ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                style={{ color: "#a7a3a3", cursor: "cursor" }}
                className="fas fa-calendar-day"
                title="Vous pouvez ajouter une justification pour votre absence ? "
              ></i>
            </div>
          ): (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                style={{ color: "#a7a3a3", cursor: "cursor" }}
                className="fas fa-calendar-day"
                title="Avis d'absence est en cours de validation "
              ></i>
            </div>
          )

        )
      )
    },
    {
      dataField: "Etat",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.etat == 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ea0b0b", cursor: "pointer" }}
              className="fas fa-spinner fa-pulse"
              title="Votre demande est en cours"
            ></i>
          </div>
        ) : row.etat == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#1a9e3a", cursor: "pointer" }}
              className="fas fa-check"
              title="Votre demande a été acepté "
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#ea0b0b", cursor: "pointer" }}
              className="fas fa-times"
              title="Votre demande a été refusé"
            ></i>
          </div>
        )
      )
    }
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resQ = await APIS.get("quart/select").then(resQ => {
      setQuart(resQ.data.Quart)
    })
    const res = await apis
      .post("avis/absence/list", { enseignant_id: enseignant_id, annee_id })
      .then(res => {
        setAvisAbsence(res.data.avis)
      })
    setLoading(true)
  }, [])


  const download2 = row => {

    API.post(
      "avis/absence/download",
      {
        id: row.id,
        annee_id: annee_id
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, `${row.enseignant}.${row.extension}`)
    })
  }
  const toggle = id => {
    setModal(!modal)
    setId(id)
  }
  const toggleJustification = id => {
    setModalJustification(!modalJustification)
    setId(id)
  }
  const toggleRat = id => {
    setModalRat(!modalRat)
    setId(id)
  }

  const toggleDelete = async () => {
    setModal(!modal)
    const res = await apis
      .post("avis/absence/delete", {
        id: id
      })
      .then(res => {
        const resD = apis
          .post("avis/absence/list", { enseignant_id: enseignant_id, annee_id })
          .then(resD => {
            setAvisAbsence(resD.data.avis)

          })
        setLoading(true)
      })
  }
  const toggleAddRatt = async () => {

    if (date == null) {
      toast.error("⛔ Date obligatoire !", {
        containerId: "A"
      })
    } else if (selectQuart == "") {
      toast.error("⛔ Quart obligatoire !", {
        containerId: "A"
      })
    } else if (selectType == "") {
      toast.error("⛔ Type obligatoire !", {
        containerId: "A"
      })
    } else if (selectMatiere == "") {
      toast.error("⛔ Matiere obligatoire !", {
        containerId: "A"
      })
    } else if (selectGroupe != "") {
      // convert date début
      let dateRatt = date
      let month = "" + (dateRatt.getMonth() + 1)
      let day = "" + dateRatt.getDate()
      let year = dateRatt.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      let convertDate = [year, month, day].join("-")
      //
      const res = await APIS.post("avis/rattrapage/add", {
        date: convertDate,
        jour_id: jourId,
        quart_id: selectQuart.value,
        matiere_id: selectMatiere.value,
        group_id: selectGroupe,
        type_id: selectType.value,
        enseignant_id: enseignant_id,
        semestre_id: selectSemestre.value,
        quan: 0,
        annee_id
      })
        .then(res => {
          setModalRat(!modalRat)
          const resD = apis
            .post("avis/absence/list", { enseignant_id: enseignant_id, annee_id })
            .then(resD => {
              setAvisAbsence(resD.data.avis)
            })
          setLoading(true)
        })
        .catch(() => {
          toast.error("Problème lors de l'insertion !", {
            containerId: "A"
          })
        })
    } else {
      toast.error("⛔ Groupe obligatoire !", {
        containerId: "A"
      })
    }
  }

  useEffect(() => {
    setModal(false)
  }, [avisAbsence])
  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0
  }

  const getQuart = e => {
    setSelectQuart(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
  }

  const getDay1 = async e => {
    setDate(e)
    setSelectQuart("")
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    // convert date début
    let date = e
    let month = "" + (date.getMonth() + 1)
    let day = "" + date.getDate()
    let year = date.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDate = [year, month, day].join("-")
    const res = await APIS.post("quart/get_date", {
      date: convertDate
    }).then(res => {
      setJourId(res.data.Date)
    })
  }
  const getTypeMatiere = async e => {
    setSelectSemestre(e)
    const res = await APIS.post("emploi/get_type_matiere", {
      enseignant_id: enseignant_id, annee_id,
      semestre_id: e.value
    }).then(res => {
      setType(res.data.emploi)
    })
  }

  const getMatiere = async e => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await APIS.post("emploi/get_matiere", {
      type: e.value,
      enseignant_id: enseignant_id, annee_id,
      semestre_id: selectSemestre.value
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }

  const getGroupe = async e => {
    setSelectMatiere(e)
    setSelectGroupe("")
    const resG = await APIS.post("emploi/get_group", {
      quart_id: selectQuart.value,
      jour_id: jourId,
      quan: 0,
      matiere_id: e.value,
      type: selectType.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value, annee_id
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
  }
  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }
  const uploadFile = async () => {
    let data = new FormData()
    data.append("file", file)
    data.append("id", id)
    data.append("annee_id", annee_id)
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        if (percent < 100) {
          setUploadPercent(percent)
        }
      }
    }
    API.post("avis/absence/import", data, options).then(res => {
      setUploadPercent(100)
      API.post("avis/absence/list",
        {
          enseignant_id: enseignant_id, annee_id
        }).then(res => {
        setUploadPercent(0)
        setModalJustification(!modalJustification)
        setAvisAbsence(res.data.avis)
        setLoading(true)
      })
    })
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={avisAbsence}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      {/* <> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px"
          }}
          toggle={toggle}
        >
          {props.t("Suppression avis d'absence")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {" "}
                {props.t(
                  "Êtes-Vous sûr de vouloir supprimer cet avis d'absence ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px"
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRat} toggle={toggleRat} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px"
          }}
          toggle={toggleRat}
        >
          {props.t("Ajouter une date de rattrapage pour votre absence")}
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Semestre")}
                  </Label>
                  <Select
                    options={semestre}
                    isSearchable={true}
                    value={selectSemestre}
                    onChange={e => getTypeMatiere(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Date")}
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </Label>
                  <DatePicker
                    selected={date}
                    filterDate={isWeekday}
                    className="form-control ddate"
                    dateFormat="dd/MM/yyyy"
                    onChange={e => getDay1(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Quart")}
                  </Label>
                  <Select
                    options={quart}
                    isSearchable={true}
                    value={selectQuart}
                    onChange={e => getQuart(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Type</Label>
                  <Select
                    options={type}
                    isSearchable={true}
                    value={selectType}
                    onChange={e => getMatiere(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Matiere</Label>
                  <Select
                    options={matiere}
                    isSearchable={true}
                    value={selectMatiere}
                    onChange={e => getGroupe(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Groupe</Label>
                  <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    isMulti={true}
                    onChange={e => setSelectGroupe(e)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {" "}
                {props.t(
                  "Êtes-Vous sûr de vouloir d'ajouter cet date de rattrapage ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px"
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleAddRatt}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Confirmer")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleRat}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalJustification} toggle={toggleJustification} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px"
          }}
          toggle={toggleJustification}
        >
          {props.t("Ajouter une justification de votre absence")}
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col lg="12">
                <div className="mb-6">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Justification")}
                  </Label>
                  {uploadPercent <= 0 ? (
                    <Input
                      onChange={e => addFile(e)}
                      className="form-control mt-4"
                      type="file"
                      id="formFile"
                      accept=".pdf, .png, .jpg, .jpeg"
                    />
                  ) : null}
                  {uploadPercent > 0 && uploadPercent < 100 ? (
                    <Progress
                      striped
                      className="progress-xl"
                      color="success"
                      value={uploadPercent}
                    >
                      {uploadPercent}%
                    </Progress>
                  ) : null}
                  {uploadPercent == 100 ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      Success
                      <i
                        style={{
                          paddingLeft: "5px",
                          color: "darkcyan",
                          paddingTop: "3.5px"
                        }}
                        className="fas fa-check me-1"
                      />
                    </div>
                  ) : null}
                </div>
              </Col>

            </Row>
            <br />
          </Form>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px"
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {" "}
                {props.t(
                  "Êtes-Vous sûr de vouloir d'ajouter cet justification d'absence ?"
                )}
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px"
              }}
            >
              {uploadPercent != 100 ? (
                <div>
                  <Button
                    color="info"
                    className="btn-rounded "
                    onClick={uploadFile}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    {props.t("Confirmer")}
                  </Button>
                </div>
              ) : null}
              <div>
                <Button
                  onClick={toggleJustification}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableAvisAbsence))
DataTableAvisAbsence.propTypes = {
  avisAbsence: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any
}
