import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import APIS from "../../apis"
//i18n
import { withTranslation } from "react-i18next"
import API from "../../apis";
const SidebarContent = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var chefDepartement = userAuthEnseignant.user.chef

  // var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var grade_id = userAuthEnseignant.user.grade_id
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })
  const historique = () => {
    API.post("enseignant/storeVuSeance", {
      enseignant_id: enseignant_id,
      annee_id:annee_id
    })
  };
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className={lng == "ar" ? "sidebar-menu-ar" : ""}>
          <ul
            className={
              lng == "ar"
                ? "metismenu-ar list-unstyled"
                : "metismenu list-unstyled"
            }
            id="side-menu"
          >
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Actualités")}</span>
              </Link>
            </li>
            {chefDepartement == 1 ? (
              <li>
                <Link to="/Enseignant" className="">
                  <i className="fas fa-chalkboard-teacher"></i>
                  <span>{props.t("Enseignant")}</span>
                </Link>
              </li>
            ) : null}

            <li>
              <Link to="/FicheVoeux" className="">
                <i className="fas fa-clipboard-list"></i>
                <span>{props.t("Fiche des voeux")}</span>
              </Link>
            </li>
            <li>
              <Link to="/DemandeTerrain" className="">
                <i className="fas fa-clipboard-list"></i>
                <span>{props.t("Demande de terrain")}</span>
              </Link>
            </li>
            <li>
              <Link to="/AvisAbsence" className="">
                <i className="fas fa-clipboard-list"></i>
                <span>{props.t("Avis d'absence")}</span>
              </Link>
            </li>
            <li>
              <Link to="/FicheVoeuxSurveillance" className="">
                <i className="fas fa-clipboard-list"></i>
                <span>{props.t("Fiche des voeux surveillance")}</span>
              </Link>
            </li>
            {chefDepartement == 1 ? (
              <li>
                <Link to="/ValidationFicheVoeux" className="">
                  <i className="fas fa-check-circle"></i>
                  <span>{props.t("Affectation libre des fiches voeux")}</span>
                </Link>
              </li>
            ) : null}
            {chefDepartement == 1 ? (
              <li>
                <Link to="/EmploiGroup" className="">
                  <i className="fas fa-calendar-alt"></i>
                  <span>{props.t("Groupe")}</span>
                </Link>
              </li>
            ) : null}

            <li onClick={historique}>
              <Link to="/EmploiEnseignant" className="">
                <i className="fas fa-calendar-day"></i>
                <span>{props.t("Emploi enseignant")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Registre" className="">
                <i className="fas fa-calendar-day"></i>
                <span>{props.t("Registre")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/NoteAndPresence" className="">
                <i className="fas fa-edit"></i>
                <span>{props.t("Note & présence")}</span>
              </Link>
            </li>
            <li>
              <Link to="/StatAbsence" className="">
                <i className="fas fa-chart-bar"></i>
                <span>{props.t("Stat absence")}</span>
              </Link>
            </li> */}
            {/*<li>*/}
            {/*  <Link to="/AvisRattrapage" className="">*/}
            {/*    <i className="fas fa-info-circle"></i>*/}
            {/*    <span>{props.t("Avis de rattrapage")}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="fas fa-pen"></i>
                <span>{props.t("Avis DS")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/AvisTest/1">{props.t("Avis DS1 / DS2")}</Link>
                </li>
                <li>
                  <Link to="/AvisTest/2">{props.t("Avis TEST")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/Surveillance" className="">
                <i className="fas fa-eye"></i>
                <span>{props.t("Surveillance")}</span>
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link to="/PageAutorisation" className="">*/}
            {/*    <i className="fas fa-pen"></i>*/}
            {/*    <span>{props.t("Note DS")}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/PageAutorisation" className="">*/}
            {/*    <i className="fas fa-pen"></i>*/}
            {/*    <span>{props.t("Note DS2")}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/#" className="has-arrow ">*/}
            {/*    <i className="fas fa-pen"></i>*/}
            {/*    <span>{props.t("Note examen")}</span>*/}
            {/*  </Link>*/}
            {/*  <ul className="sub-menu" aria-expanded="false">*/}
            {/*    <li>*/}
            {/*      <Link to="/PageAutorisation">*/}
            {/*        {props.t("Principale")}*/}
            {/*      </Link>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <Link to="/PageAutorisation">{props.t("Contrôle")}</Link>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/#" className="has-arrow ">*/}
            {/*    <i className="fas fa-graduation-cap"></i>*/}
            {/*    <span>{props.t("Resultat examen")}</span>*/}
            {/*  </Link>*/}
            {/*  <ul className="sub-menu" aria-expanded="false">*/}
            {/*    <li>*/}
            {/*      <Link to="/PageAutorisation">*/}
            {/*        {props.t("Principale")}*/}
            {/*      </Link>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <Link to="/PageAutorisation">{props.t("Contrôle")}</Link>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</li>*/}

            <li>
              <a className="has-arrow ">
                <i className="fas fa-folder-open"></i>{" "}
                <span>{props.t("Bibliothèque")}</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/PageAutorisation" className="">
                    <span>{props.t("Demande Livre")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/PageAutorisation" className="">
                    <span>{props.t("Consultation demande")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/PageAutorisation" className="">
                <i className="fas fa-print"></i>
                <span>{props.t("Demande Tirage")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
