import React, {useEffect, useState} from "react"
import {Button, Col, Form, Input, Label, Progress, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import Select from "react-select";

const AddAbsence = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [disbutReg, setDisbutReg] = useState(true)
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const save = async () => {
    if(selectSemestre === ""){
      setDisbutReg(true)
      toast.error("⛔ Semestre obligatoire", {
        containerId: "A",
      })
    }else if(selectType === ""){
      setDisbutReg(true)
      toast.error("⛔ Type obligatoire", {
        containerId: "A",
      })
    }else if(selectMatiere === ""){
      setDisbutReg(true)
      toast.error("⛔ Matière obligatoire", {
        containerId: "A",
      })
    }else if(selectGroupe !== ""){
      const res = await API.post("registreGroup/store", {
        annee_id,
        enseignant_id: enseignant_id,
        matiere_id: selectMatiere.value,
        type_matiere: selectType.value,
        semestre_id: selectSemestre.value,
        group_id: selectGroupe.value,
      }).then(res => {
        if(res.data.errNum == 501){
          setDisbutReg(true)
          toast.error("⛔ Vouz avez déjà ajouter à cette groupe !", {
            containerId: "A",
          })
        }else{
          setDisbutReg(false)
          props.setSection(1)
        }
      })
    }else{
      setDisbutReg(true)
      toast.error("⛔ Groupe obligatoire", {
        containerId: "A",
      })
    }
  }
  const getMatiere = async (e) => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")

    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      semestre_id: selectSemestre.value,
      enseignant_id: enseignant_id,
      annee_id: annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }
  const getType = async (e) => {
    setSelectSemestre(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")

    const res = await API.post("emploi/get_type_matiere", {
      annee_id:annee_id,
      enseignant_id: enseignant_id,
      semestre_id: e.value,
    }).then(res => {
      setType(res.data.emploi)
    })
  }
  const getGroup = async (e) => {
    setSelectMatiere(e)
    setSelectGroupe("")

    const res = await API.post("registre/get_group_by_enseignant_id", {
      enseignant_id: enseignant_id,
      matiere_id:e.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setGroupe(res.data.Group)
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Semestre
                </Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  onChange={e => getType(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                  options={type}
                  isSearchable={true}
                  value={selectType}
                  onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                  options={matiere}
                  isSearchable={true}
                  value={selectMatiere}
                  onChange={e => getGroup(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                  options={groupe}
                  isSearchable={true}
                  value={selectGroupe}
                  onChange={e => setSelectGroupe(e)}
                />
              </div>
            </Col>
          </Row>
          <div className="mt-2">
            <h5 style={{ color: "red" }}>
              {" "}
              NB : Cette action est irréversible
            </h5>
            <p>
              {
                "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
              }
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  disabled={!disbutReg}
                  onClick={save}
                >
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>

        </Form>

      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddAbsence
AddAbsence.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
