import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import APIE from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalHeader, ModalBody, Button, Label } from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

const DetailDemandeTerrain = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [dure, setDure] = useState([
    {value: 1, label: "1h", key: "1"},
    {value: 2, label: "1h:30", key: "1.5"},
    {value: 3, label: "2h", key: "2"},
    {value: 4, label: "2h:30", key: "2.5"},
    {value: 5, label: "3h", key: "3"},
    {value: 6, label: "3h:30", key: "3.5"},
    {value: 7, label: "4h", key: "4"},
  ])
  const [selectDure, setSelectDure] = useState("");
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [id, setId] = useState("")
  const [groupeId, setGroupeId] = useState("")
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [annee, setAnnee] = useState("");
  const [date, setDate] = useState("")
  const [quart, setQuart] = useState([])
  const [selectQuart, setSelectQuart] = useState("")
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "groupe",
      text: "Groupe",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: "Matière",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: "Date",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "heure_debut",
      text: "Heure début",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "duree",
      text: "Durée",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Etat Demande",
      isDummyField: true,
      text: "Etat Demande",
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
           row.etat === 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                  style={{color: "#fb0000", cursor: "pointer"}}
                  className="fas fa-spinner fa-pulse font-size-18"
                  title="Cette demande est en cours"
                ></i>
              </div>
            ) : row.etat === 2 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  style={{ color: "#18ae3d", cursor: "pointer" }}
                  className="fas fa-check font-size-18"
                  title="Ce demande est validée"
                ></i>
              </div>
            ):row.etat === 3 ?  (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  style={{ color: "#ff0000", cursor: "pointer" }}
                  className="fas fa-times font-size-18"
                  title="Cette demande est refusée"
                ></i>
              </div>
            ): (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  style={{ color: "#a7a3a3", cursor: "pointer" }}
                  className="fas fa-plus-circle font-size-18"
                  title="Ce grade déja utilisé"
                ></i>
              </div>
            )
    },
    {
      dataField: "Ajouter Demande",
      isDummyField: true,
      text: "Ajouter Demande",
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat === 0 || row.etat === 3 ? (
          <div style={{display: "flex", justifyContent: "center"}}>
            <i
              style={{color: "#788ff6", cursor: "pointer"}}
              onClick={() => toggle(row)}
              className="fas fa-plus-circle font-size-18"
            ></i>
          </div>
        ): (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-plus-circle font-size-18"
              title="Vous avez déjà ajouter une demande "
            ></i>
          </div>
        )
    },
    {
      dataField: "Supprimer Demande",
      isDummyField: true,
      text: "Supprimer Demande",
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat === 1 ? (
          <div style={{display: "flex", justifyContent: "center"}}>
            <i
              style={{color: "#ea0b0b", cursor: "pointer"}}
              onClick={() => toggleDeleteDemande(row)}
              className="fas fa-trash-alt font-size-18"
            ></i>
          </div>
        ): (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#a7a3a3", cursor: "pointer" }}
              className="fas fa-trash-alt font-size-18"
              title="Vous avez déjà ajouter une demande "
            ></i>
          </div>
        )
    },
  ]

  const { SearchBar } = Search
  const toggle = row => {
    setModal(!modal)
    setId(row.id)
    setGroupeId(row.groupe_id)
    setDate("")
    setSelectDure("")
    setSelectQuart("")
  }
  const toggleDeleteDemande = row => {
    setModalDelete(!modalDelete)
    setId(row.id_demande)
  }
  const toggleSave = async () => {
    if (date === "") {
      toast.error("⛔ Date obligatoires", {
        containerId: "A",
      })
    }else if( selectDure ==="" ){
      toast.error("⛔ Durée obligatoires", {
        containerId: "A",
      })
    }else if( selectQuart === ""){
      toast.error("⛔ Quart obligatoires", {
        containerId: "A",
      })
    }else{
      if (date != "") {
        let dateNaiss = date
        let month = "" + (dateNaiss.getMonth() + 1)
        let day = "" + dateNaiss.getDate()
        let year = dateNaiss.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        var convertDate = [year, month, day].join("-")
      } else {
        var convertDate = ""
      }
      const res = await API.post("demandeTerrain/passer_demande", {
        id: id,
        annee_id,
        quart_id:selectQuart.value,
        duree:selectDure.key,
        date:convertDate
      }).then(res => {
        setModal(false)
        setDate("")
        setSelectDure("")
        setSelectQuart("")
        const resD = API.post("demandeTerrain/list_ens",{
          ens_id: enseignant_id,
          semestre_id: props.semestre_value,
          annee_id:annee_id
        }).then(resD => {
          setOrders(resD.data.grp_ens_mat)
          setLoading(true)
        })
      })
    }

  }
  const toggleDeleteDem = async () => {
    const res = await API.post("demandeTerrain/destroy", {
      id_demande: id,
      annee_id
    }).then(res => {
      setModalDelete(false)
      const resD = API.post("demandeTerrain/list_ens", {
        ens_id: enseignant_id,
        semestre_id: props.semestre_value,
        annee_id: annee_id
      }).then(resD => {
        setOrders(resD.data.grp_ens_mat)
        setLoading(true)
      })
    })
  }
  useEffect(async () => {
      const res = API.post("demandeTerrain/list_ens", {
        ens_id: enseignant_id,
        semestre_id: props.semestre_value,
        annee_id:annee_id
      }).then(res => {
        setOrders(res.data.grp_ens_mat)
        setLoading(true)
      })

  }, [props.semestre_value])
  const handleChange = (selectedDate) => {
    setDate(selectedDate);
  };
  const getQuart = async (e) => {
    setSelectDure(e)
    if (date != "") {
      let dateNaiss = date
      let month = "" + (dateNaiss.getMonth() + 1)
      let day = "" + dateNaiss.getDate()
      let year = dateNaiss.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
      toast.error("⛔ Date obligatoires", {
        containerId: "A",
      })
    }
    API.post("demandeTerrain/get_quart_libre",{
      group_id:groupeId,
      date:convertDate,
      semestre_id:props.semestre_value,
      annee_id,
      nb_heures:e.key,
      enseignant_id
    }).then(resQ => {
      setQuart(resQ.data.quarts)
    })
  }
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
          style={{width: "100% !important", textAlign: "center !important"}}
          toggle={toggle}
          tag="h4"
        >
          Ajouter une date pour la demande de terrain.

        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div >
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Date de seance :
                    </Label>
                    <Col className="pr-0">
                      <DatePicker
                        className="form-control ddate"
                        selected={date}
                        dateFormat="dd/MM/yyyy"
                        onChange={handleChange}
                        filterDate={(date) => {
                          return date.getDay() !== 0 ;
                        }}
                      />
                    </Col>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Durée de seance :
                    </Label>
                    <Col className="pr-0">
                      <Select
                        options={dure}
                        isSearchable={true}
                        onChange={e => getQuart(e)}
                        value={selectDure}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Quart de début :
                    </Label>
                    <Col className="pr-0">
                      <Select
                        options={quart}
                        isSearchable={true}
                        onChange={e => setSelectQuart(e)}
                        value={selectQuart}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleSave}
                >
                  <i
                    style={{color: "white"}}
                    className="far fa-thumbs-up"
                  ></i>
                  Confirmer
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{color: "white"}}
                    className="far fa-thumbs-down"
                  ></i>
                  Annuler
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDeleteDemande} centered={true}>
        <ModalHeader
          style={{width: "100% !important", textAlign: "center !important"}}
          toggle={toggleDeleteDemande}
          tag="h4"
        >
          {"Suppression d'une demande de terrain"}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{display: "flex", justifyContent: "center"}}>
              <p>
                {"Voulez - vous supprimer cette demande ?"}

              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDeleteDem}
                >
                  <i
                    style={{color: "white"}}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDeleteDemande}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{color: "white"}}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DetailDemandeTerrain)
DetailDemandeTerrain.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  t: PropTypes.any,
}
