import React, {useEffect, useState} from "react"
import {Button, Col, Form, Input, Label, Progress, Row, Table} from "reactstrap"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import Select from "react-select";

const AddRegistre = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  const [disbutReg, setDisbutReg] = useState(true)
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [etudiantList, setEtudiantList] = useState([])
  const [affiche, setAffiche] = useState(false)
  const save = async () => {
    setDisbutReg(false)
    const res = await API.post("registre/add", {
      annee_id,
      enseignant_id: enseignant_id,
      matiere_id: selectMatiere.value,
      type_matiere: selectType.value,
      semestre_id: selectSemestre.value,
      group_id: selectGroupe.value,
      etudiants: etudiantList,
    }).then(res => {
      if(res.data.errNum == 501){
        setDisbutReg(true)
        toast.error("⛔ Vouz avez déjà ajouter à cette groupe !", {
          containerId: "A",
        })
      }else{
        setDisbutReg(false)
        props.setSection(1)
      }
    })
  }
  const getMatiere = async (e) => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    setEtudiantList([])
    setAffiche(false)
    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      semestre_id: selectSemestre.value,
      enseignant_id: enseignant_id,
      annee_id: annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }
  const getType = async (e) => {
    setSelectSemestre(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setEtudiantList([])
    setAffiche(false)
    const res = await API.post("emploi/get_type_matiere", {
      annee_id:annee_id,
      enseignant_id: enseignant_id,
      semestre_id: e.value,
    }).then(res => {
      setType(res.data.emploi)
    })
  }
  const getGroup = async (e) => {
    setSelectMatiere(e)
    setSelectGroupe("")
    setEtudiantList([])
    setAffiche(false)
    const res = await API.post("registre/get_group_by_enseignant_id", {
      enseignant_id: enseignant_id,
      matiere_id:e.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setGroupe(res.data.Group)
    })
  }
  const display = () => {
    if(selectSemestre == "" || selectType =="" || selectMatiere =="" || selectGroupe==""){
      setAffiche(false)
      toast.error("⛔ Tous les champs sont obligatoire !", {
        containerId: "A",
      })
    }else{
      setAffiche(true)
      API.post("registre/get_etudiant_by_group_id", {
        type_matiere: selectType.value,
        semestre_id: selectSemestre.value,
        group_id:selectGroupe.value,
        annee_id:annee_id,
      }).then(res => {
        setEtudiantList(res.data.Etudiant)
      })
    }
  }
  const changeAbsence = (event, index) => {
    let absence = event.target.checked
    if (absence == 1) {
      setEtudiantList(
          etudiantList.map((el, id) =>
              id === index ? Object.assign(el, { presence: 1}) : el
          )
      )
    } else {
      setEtudiantList(
          etudiantList.map((el, id) =>
              id === index ? Object.assign(el, { presence: 0}) : el
          )
      )
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Semestre
                </Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    onChange={e => getType(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                    options={type}
                    isSearchable={true}
                    value={selectType}
                    onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                    options={matiere}
                    isSearchable={true}
                    value={selectMatiere}
                    onChange={e => getGroup(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => setSelectGroupe(e)}
                />
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}

            <Col lg="6">
              <div className="text-center mt-4">
                <button
                    type="button"
                    className="btn btn-primary "
                    onClick={display}>
                  Afficher
                </button>
              </div>
            </Col>
          </div>
          {affiche ? (
          <div>
            <Table className="mt-3" striped bordered hover>
              <thead>
              <tr>
                <th style={{ textAlign: "center" }}>CIN</th>
                <th style={{ textAlign: "center" }}>Etudiant</th>
                <th style={{ textAlign: "center" }}>Présence</th>
              </tr>
              </thead>
              <tbody>
              {etudiantList.map((el, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {el.cin} </td>
                    <td style={{ textAlign: "center" }}> {el.name} </td>
                    <td className="square-switch" style={{ textAlign: "center" }} >
                      <input
                          type="checkbox"
                          id={"square-switch" + index}
                          switch="bool"
                          checked={el.presence}
                          onChange={e => changeAbsence(e, index)}
                      />
                      <label
                          htmlFor={"square-switch" + index}
                          data-on-label="P"
                          data-off-label="A"
                      />
                    </td>
                  </tr>
              ))}
              </tbody>
            </Table>
            <div className="mt-2">
              <h5 style={{ color: "red" }}>
                {" "}
                NB : Cette action est irréversible
              </h5>
              <p>
                {
                  "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                }
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                >
                  <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={props.back}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                >
                  <button
                      type="button"
                      className="btn btn-primary "
                      disabled={!disbutReg}
                      onClick={save}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </div>

              ):(null)}
        </Form>

      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AddRegistre
AddRegistre.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
